export const query = `query getCheckoutSettings {
  checkoutSettings {
    logo {
      url
      mediaType
      title
      width
      height
    }
    storeName
    termsAndConditions {
      value
      enabled
    }
    privacyPolicy {
      value
      enabled
    }
    digitalItemPolicy {
      value
      enabled
    }
    refundCancellationPolicy {
      value
      enabled
    }
    contactUs {
      value
      enabled
    }
    customPolicy {
      value
      enabled
      title
    }
    checkoutHeader {
        text,
        displayCheckoutLabel,
        alignment,
        logo {
            size,
        }
    }
  }
}`;
